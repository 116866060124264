import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import * as service from "../../services";
import { getUUID, startLoading, stopLoading } from "../../components/Util";
import { useNavigate } from "react-router-dom";
import ConfirmDialog from "../../components/ConfirmDialog";
import { useAuth } from "../../context/auth";

function CreateTargets(props) {

  const { id } = useParams();
  let { user } = useAuth();
  const navigate = useNavigate();
  const [loading] = useState(true);
  const [recipe, setRecipe] = useState({ target_type_checked: 'FTP' });
  const [errors, setErrors] = useState({});
  const [showConfirmDialog, setshowConfirmDialog] = useState(false)
  const [confDialogRecipe, setconfDialogRecipe] = useState({})

  useEffect(() => {
    let p1 = new Promise((resolve, reject) => {
      if (id !== '0') {
        service.get_targets({ id }, res => {
          if (res.data) {
            setRecipe({ ...res.data[0] });
            resolve("");
          }
        });
      } else resolve("");
    });

    Promise.all([p1])
      .then(values => {
        stopLoading();
      })
      .catch(error => {
        console.log(error.message);
      });

  }, [id]);

  useEffect(() => {
    if (!loading) {
      console.log("loaded...");
    }
  }, [loading]);

  const handleChange = (prop, value) => {
    setRecipe(recipe => ({ ...recipe, [prop]: value }));
  };

  const validate = () => {

    setErrors({});
    let isValid = true;
    let validation = [
      { field: 'target_name', msg: 'Please enter Target Name.' }
    ];

    if (recipe.target_type_checked === 'FTP') {
      validation.push(
        { field: 'ftp_ip_addres', msg: 'Please enter IP Address.' },
        { field: 'ftp_port', msg: 'Please enter Port.' },
        { field: 'ftp_username', msg: 'Please enter User Name.' },
        { field: 'ftp_password', msg: 'Please enter Password.' }
      )
    }

    if (recipe.target_type_checked === 'FTP' && recipe.ftp_password) {
      if (recipe.ftp_password.length < 8) {
        validation.push(
          { field: 'ftp_password_length_error', msg: 'Password length must be of 8-32 characters.' }
        )
      }
    }

    // if (recipe.target_type_checked === 'SSH') {
    //   validation.push(
    //     { field: 'ssh_ip_addres', msg: 'Please enter IP Address.' },
    //     { field: 'ssh_port', msg: 'Please enter Port.' },
    //     { field: 'ssh_username', msg: 'Please enter User Name.' },
    //     { field: 'ssh_password', msg: 'Please enter Password.' },
    //     { field: 'ssh_config_file', msg: 'Please enter config file.' }
    //   )
    // }

    if (recipe.target_type_checked === 'SCP') {
      validation.push({ field: 'scp_based_type', msg: 'Please select based type.' })
      if (recipe.scp_based_type === 'Key Based' || recipe.scp_based_type === 'Password Based') {
        validation.push(
          { field: 'scp_host_name', msg: 'Please enter host Name.' },
          { field: 'scp_port', msg: 'Please enter Port.' },
          { field: 'scp_user_name', msg: 'Please enter User Name.' },
          { field: 'scp_password', msg: 'Please enter Password.' })
      }

      if (recipe.scp_based_type === 'Key Based') validation.push({ field: 'scp_key_value', msg: 'Please enter key value.' })
    }

    if (recipe.target_type_checked === 'SCP' && (recipe.scp_based_type === 'Key Based' || recipe.scp_based_type === 'Password Based') && recipe.scp_password) {
      if (recipe.scp_password.length < 8) {
        validation.push(
          { field: 'scp_password_length_error', msg: 'Password length must be of 8-32 characters.' }
        )
      }
    }

    if (recipe.target_type_checked === 'SFTP') {
      validation.push({ field: 'sftp_based_type', msg: 'Please select based type.' })
      if (recipe.sftp_based_type === 'Key Based' || recipe.sftp_based_type === 'Password Based') {
        validation.push(
          { field: 'sftp_host_name', msg: 'Please enter host Name.' },
          { field: 'sftp_port', msg: 'Please enter Port.' },
          { field: 'sftp_user_name', msg: 'Please enter User Name.' },
          { field: 'sftp_password', msg: 'Please enter Password.' })
      }

      if (recipe.sftp_based_type === 'Key Based') validation.push({ field: 'sftp_key_value', msg: 'Please enter key value.' })
    }

    if (recipe.target_type_checked === 'SFTP' && (recipe.sftp_based_type === 'Key Based' || recipe.sftp_based_type === 'Password Based') && recipe.sftp_password) {
      if (recipe.sftp_password.length < 8) {
        validation.push(
          { field: 'sftp_password_length_error', msg: 'Password length must be of 8-32 characters.' }
        )
      }
    }
    validation.map(item => {
      if (!recipe[item.field] || recipe[item.field].length <= 0) {
        setErrors(errors => ({ ...errors, [item.field]: item.msg }));
        isValid = false;
      }
      return item;
    });

    return isValid;
  };

  const upsert = () => {
    if (validate()) {
      startLoading();
      let data = JSON.parse(JSON.stringify(recipe));
      data.id = id === '0' ? getUUID() : data.id;
      data.ftp_ip_addres = recipe.target_type_checked === 'FTP' ? recipe.ftp_ip_addres : "";
      data.ftp_port = recipe.target_type_checked === 'FTP' ? recipe.ftp_port : "";
      data.ftp_username = recipe.target_type_checked === 'FTP' ? recipe.ftp_username : "";
      data.ftp_password = recipe.target_type_checked === 'FTP' ? recipe.ftp_password : "";
      // data.ssh_ip_addres = recipe.target_type_checked === 'SSH' ? recipe.ssh_ip_addres : "";
      // data.ssh_port = recipe.target_type_checked === 'SSH' ? recipe.ssh_port : "";
      // data.ssh_username = recipe.target_type_checked === 'SSH' ? recipe.ssh_username : "";
      // data.ssh_password = recipe.target_type_checked === 'SSH' ? recipe.ssh_password : "";
      // data.ssh_config_file = recipe.target_type_checked === 'SSH' ? recipe.ssh_config_file : "";

      if (recipe.target_type_checked === 'SCP') {
        if (recipe.scp_based_type === 'Key Based' || recipe.scp_based_type === 'Password Based') {
          data.scp_host_name = recipe.scp_host_name || "";
          data.scp_port = recipe.scp_port || "";
          data.scp_user_name = recipe.scp_user_name || "";
          data.scp_password = recipe.scp_password || "";
        }

        if (recipe.scp_based_type === 'Key Based') data.scp_key_value = recipe.scp_key_value || "";

      }

      if (recipe.target_type_checked === 'SFTP') {
        if (recipe.sftp_based_type === 'Key Based' || recipe.sftp_based_type === 'Password Based') {
          data.sftp_host_name = recipe.sftp_host_name || "";
          data.sftp_port = recipe.sftp_port || "";
          data.sftp_user_name = recipe.sftp_user_name || "";
          data.sftp_password = recipe.sftp_password || "";
        }

        if (recipe.sftp_based_type === 'Key Based') data.sftp_key_value = recipe.sftp_key_value || "";

      }

      data.operation_type = id === "0" ? "INSERT" : "UPDATE";
      data.status_id = 'ACTIVE';

      service.upsert_target({ data }, res => {
        stopLoading();
        captureAudit(data)
        setshowConfirmDialog(true)
        setconfDialogRecipe({
          msg: 'Target has been ' + (id !== '0' ? 'updated.' : 'created.'),
          goback: true
        })
      });
    }
  }

  const captureAudit = (data) => {
    data.after_json = { ...data };
    data.before_json = {};
    data.ref_id = data.id;
    data.menu_id = 'Target';
    data.action_by_name = user.first_name + " " + user.last_name;
    data.action_by_id = user.id;
    data.action_type = id === "0" ? "CREATE" : "UPDATE";
    data.version_id = 0;
    data.url_path = '/target/view-targets-history/';

    service.upsert_history({ data }, res => {

    });
  }

  return (
    <>
      <div className="container">
        <br />
        <h5 className="subtitle is-3 mb-2" style={{ display: "inline", verticalAlign: "middle", paddingRight: "35%" }}>
          Create Targets
        </h5>
        <hr />
        <div className="field is-horizontal">
          <div className="field-label is-normal">
            <label className="label">Target Name</label>
          </div>
          <div className="field-body">
            <div className="field">
              <div className="control">
                <input type="text" disabled={id !== '0'} placeholder="Enter Target Name" className="input" maxLength="100" value={recipe.target_name || ''}
                  onChange={(e) => handleChange('target_name', e.target.value)} />
                {errors.target_name && <div className="help is-danger"><small>{errors.target_name}</small></div>}
              </div>
            </div>
          </div>
        </div>

        <div className="field is-horizontal pt-1 pb-1">
          <div className="field-label">
            <label className="label">&nbsp;</label>
          </div>
          <div className="field-body">
            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" disabled={id !== '0'} name="ttype" value="FTP" checked={recipe.target_type_checked === 'FTP'}
                    onChange={(e) => handleChange("target_type_checked", e.target.value)}
                  /><span className="radiomark"></span>
                </span> FTP
              </label>
            </div>

            {/* <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" name="ttype" value="SSH" checked={recipe.target_type_checked === 'SSH'}
                    onChange={(e) => handleChange("target_type_checked", e.target.value)}
                  /><span className="radiomark"></span>
                </span> SSH
              </label>
            </div> */}

            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" disabled={id !== '0'} name="ttype" value="SCP" checked={recipe.target_type_checked === 'SCP'}
                    onChange={(e) => handleChange("target_type_checked", e.target.value)}
                  /><span className="radiomark"></span>
                </span> SCP
              </label>
            </div>

            <div className="field is-narrow">
              <label className="checkbox">
                <span className="custom-radio">
                  <input type="radio" disabled={id !== '0'} name="ttype" value="SFTP" checked={recipe.target_type_checked === 'SFTP'}
                    onChange={(e) => handleChange("target_type_checked", e.target.value)}
                  /><span className="radiomark"></span>
                </span> SFTP
              </label>
            </div>
          </div>
        </div>

        {recipe.target_type_checked === 'FTP' && <article className="message is-light">
          <div className="message-header">FTP</div>
          <div className="p-2">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">IP Address</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" disabled={id !== '0'} placeholder="Enter FTP IP Address" className="input" pattern="[0-9.]*" maxLength="12" value={recipe.ftp_ip_addres || ''}
                      onChange={(e) => handleChange('ftp_ip_addres', (e.target.validity.valid) ? e.target.value : recipe.ftp_ip_addres)} />
                    {errors.ftp_ip_addres && <div className="help is-danger"><small>{errors.ftp_ip_addres}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Port</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" disabled={id !== '0'} placeholder="Enter FTP Port" className="input" pattern="[0-9]*" maxLength="5" value={recipe.ftp_port || ''}
                      onChange={(e) => handleChange('ftp_port', (e.target.validity.valid) ? e.target.value : recipe.ftp_port)} />
                    {errors.ftp_port && <div className="help is-danger"><small>{errors.ftp_port}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">UserName</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" disabled={id !== '0'} placeholder="Enter FTP UserName" className="input" maxLength="100" value={recipe.ftp_username || ''}
                      onChange={(e) => handleChange('ftp_username', e.target.value)} />
                    {errors.ftp_username && <div className="help is-danger"><small>{errors.ftp_username}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Password</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="password" disabled={id !== '0'} placeholder="Enter FTP Password" maxLength="32" className="input" value={recipe.ftp_password || ''}
                      onChange={(e) => handleChange('ftp_password', e.target.value)} />
                    {errors.ftp_password && <div className="help is-danger"><small>{errors.ftp_password}</small></div>}
                    {errors.ftp_password_length_error && <div className="help is-danger"><small>{errors.ftp_password_length_error}</small></div>}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>}


        {/* {recipe.target_type_checked === 'SSH' && <article className="message is-light">
          <div className="message-header">SSH</div>
          <div className="p-2">
            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">IP Address</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" placeholder="Enter SSH IP Address" className="input" value={recipe.ssh_ip_addres || ''}
                      onChange={(e) => handleChange('ssh_ip_addres', e.target.value)} />
                    {errors.ssh_ip_addres && <div className="help is-danger"><small>{errors.ssh_ip_addres}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Port</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" placeholder="Enter SSH Port" className="input" maxLength="5" value={recipe.ssh_port || ''}
                      onChange={(e) => handleChange('ssh_port', e.target.value)} />
                    {errors.ssh_port && <div className="help is-danger"><small>{errors.ssh_port}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">UserName</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" placeholder="Enter SSH UserName" className="input" value={recipe.ssh_username || ''}
                      onChange={(e) => handleChange('ssh_username', e.target.value)} />
                    {errors.ssh_username && <div className="help is-danger"><small>{errors.ssh_username}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Password</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" placeholder="Enter SSH Password" className="input" value={recipe.ssh_password || ''}
                      onChange={(e) => handleChange('ssh_password', e.target.value)} />
                    {errors.ssh_password && <div className="help is-danger"><small>{errors.ssh_password}</small></div>}
                  </div>
                </div>
              </div>
            </div>

            <div className="field is-horizontal">
              <div className="field-label is-normal">
                <label className="label">Config File</label>
              </div>
              <div className="field-body">
                <div className="field">
                  <div className="control">
                    <input type="text" placeholder="Enter SSH Config File" className="input" value={recipe.ssh_config_file || ''}
                      onChange={(e) => handleChange('ssh_config_file', e.target.value)} />
                    {errors.ssh_config_file && <div className="help is-danger"><small>{errors.ssh_config_file}</small></div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </article>} */}


        {recipe.target_type_checked === 'SCP' && <article className="message is-light">
          <div className="message-header">SCP</div>
          <div className="p-2">

            <div className="field is-horizontal pt-1 pb-1">
              <div className="field-label">
                <label className="label">&nbsp;</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="checkbox">
                    <span className="custom-radio">
                      <input type="radio" disabled={id !== '0'} name="scp" value="Key Based" checked={recipe.scp_based_type === 'Key Based'}
                        onChange={(e) => handleChange("scp_based_type", e.target.value)}
                      /><span className="radiomark"></span>
                    </span> Key Based
                  </label>
                </div>

                <div className="field is-narrow">
                  <label className="checkbox">
                    <span className="custom-radio">
                      <input type="radio" disabled={id !== '0'} name="scp" value="Password Based" checked={recipe.scp_based_type === 'Password Based'}
                        onChange={(e) => handleChange("scp_based_type", e.target.value)}
                      /><span className="radiomark"></span>
                    </span> Password Based
                  </label>
                </div>
                {errors.scp_based_type && <div className="help is-danger"><small>{errors.scp_based_type}</small></div>}
              </div>
            </div>

            {(recipe.scp_based_type === 'Key Based' || recipe.scp_based_type === 'Password Based') &&
              <div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field w-half">
                      <div className="is-normal">
                        <label className="label">Host Name / IP Address</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SCP Host Name / IP Address" className="input" pattern="[0-9.]*" maxLength="12" value={recipe.scp_host_name || ''}
                          onChange={(e) => handleChange('scp_host_name', (e.target.validity.valid) ? e.target.value : recipe.scp_host_name)} />
                        {errors.scp_host_name && <div className="help is-danger"><small>{errors.scp_host_name}</small></div>}
                      </div>
                    </div>

                    <div className="field  w-half">
                      <div className="is-normal">
                        <label className="label">Port</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SCP Port" className="input"pattern="[0-9]*" maxLength="5" value={recipe.scp_port || ''}
                          onChange={(e) => handleChange('scp_port', (e.target.validity.valid) ? e.target.value : recipe.scp_port)} />
                        {errors.scp_port && <div className="help is-danger"><small>{errors.scp_port}</small></div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field w-half">
                      <div className="is-normal">
                        <label className="label">User Name</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SCP User Name" className="input" maxLength="100" value={recipe.scp_user_name || ''}
                          onChange={(e) => handleChange('scp_user_name', e.target.value)} />
                        {errors.scp_user_name && <div className="help is-danger"><small>{errors.scp_user_name}</small></div>}
                      </div>
                    </div>

                    <div className="field  w-half">
                      <div className="is-normal">
                        <label className="label">Password</label>
                      </div>
                      <div className="control">
                        <input type="password" disabled={id !== '0'} placeholder="Enter SCP Password" className="input" maxLength='32' value={recipe.scp_password || ''}
                          onChange={(e) => handleChange('scp_password', e.target.value)} />
                        {errors.scp_password && <div className="help is-danger"><small>{errors.scp_password}</small></div>}
                        {errors.scp_password_length_error && <div className="help is-danger"><small>{errors.scp_password_length_error}</small></div>}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }



            {recipe.scp_based_type === 'Key Based' &&
              <div className="field is-horizontal mt-2">
                <div className="field-label is-normal">
                  <label className="label">&nbsp;</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <textarea type="text" disabled={id !== '0'} placeholder="Enter SCP key value" className="textarea" value={recipe.scp_key_value || ''}
                        onChange={(e) => handleChange('scp_key_value', e.target.value)} />
                      {errors.scp_key_value && <div className="help is-danger"><small>{errors.scp_key_value}</small></div>}
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </article>}

        {recipe.target_type_checked === 'SFTP' && <article className="message is-light">
          <div className="message-header">SFTP</div>
          <div className="p-2">

            <div className="field is-horizontal pt-1 pb-1">
              <div className="field-label">
                <label className="label">&nbsp;</label>
              </div>
              <div className="field-body">
                <div className="field is-narrow">
                  <label className="checkbox">
                    <span className="custom-radio">
                      <input type="radio" disabled={id !== '0'} name="sftp" value="Key Based" checked={recipe.sftp_based_type === 'Key Based'}
                        onChange={(e) => handleChange("sftp_based_type", e.target.value)}
                      /><span className="radiomark"></span>
                    </span> Key Based
                  </label>
                </div>

                <div className="field is-narrow">
                  <label className="checkbox">
                    <span className="custom-radio">
                      <input type="radio" disabled={id !== '0'} name="sftp" value="Password Based" checked={recipe.sftp_based_type === 'Password Based'}
                        onChange={(e) => handleChange("sftp_based_type", e.target.value)}
                      /><span className="radiomark"></span>
                    </span> Password Based
                  </label>
                </div>
                {errors.sftp_based_type && <div className="help is-danger"><small>{errors.sftp_based_type}</small></div>}
              </div>
            </div>

            {(recipe.sftp_based_type === 'Key Based' || recipe.sftp_based_type === 'Password Based') &&
              <div>
                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field w-half">
                      <div className="is-normal">
                        <label className="label">Host Name / IP Address</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SFTP Host Name / IP Address" className="input" pattern="[0-9.]*" maxLength="12" value={recipe.sftp_host_name || ''}
                          onChange={(e) => handleChange('sftp_host_name', (e.target.validity.valid) ? e.target.value : recipe.sftp_host_name)} />
                        {errors.sftp_host_name && <div className="help is-danger"><small>{errors.sftp_host_name}</small></div>}
                      </div>
                    </div>

                    <div className="field  w-half">
                      <div className="is-normal">
                        <label className="label">Port</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SFTP Port" className="input" pattern="[0-9]*" maxLength="5" value={recipe.sftp_port || ''}
                          onChange={(e) => handleChange('sftp_port', (e.target.validity.valid) ? e.target.value : recipe.sftp_port)} />
                        {errors.sftp_port && <div className="help is-danger"><small>{errors.sftp_port}</small></div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="field is-horizontal">
                  <div className="field-label is-normal">
                    <label className="label">&nbsp;</label>
                  </div>
                  <div className="field-body">
                    <div className="field w-half">
                      <div className="is-normal">
                        <label className="label">User Name</label>
                      </div>
                      <div className="control">
                        <input type="text" disabled={id !== '0'} placeholder="Enter SFTP User Name" className="input" maxLength="100" value={recipe.sftp_user_name || ''}
                          onChange={(e) => handleChange('sftp_user_name', e.target.value)} />
                        {errors.sftp_user_name && <div className="help is-danger"><small>{errors.sftp_user_name}</small></div>}
                      </div>
                    </div>

                    <div className="field  w-half">
                      <div className="is-normal">
                        <label className="label">Password</label>
                      </div>
                      <div className="control">
                        <input type="password" disabled={id !== '0'} placeholder="Enter SFTP Password" className="input" maxLength='32' value={recipe.sftp_password || ''}
                          onChange={(e) => handleChange('sftp_password', e.target.value)} />
                        {errors.sftp_password && <div className="help is-danger"><small>{errors.sftp_password}</small></div>}
                        {errors.sftp_password_length_error && <div className="help is-danger"><small>{errors.sftp_password_length_error}</small></div>}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            }

            {recipe.sftp_based_type === 'Key Based' &&
              <div className="field is-horizontal mt-2">
                <div className="field-label is-normal">
                  <label className="label">&nbsp;</label>
                </div>
                <div className="field-body">
                  <div className="field">
                    <div className="control">
                      <textarea type="text" disabled={id !== '0'} placeholder="Enter SFTP key value" className="textarea" value={recipe.sftp_key_value || ''}
                        onChange={(e) => handleChange('sftp_key_value', e.target.value)} />
                      {errors.sftp_key_value && <div className="help is-danger"><small>{errors.sftp_key_value}</small></div>}
                    </div>
                  </div>
                </div>
              </div>
            }

          </div>
        </article>}

        <div className="field is-horizontal">
          <div className="field-body">
            <div className="field is-grouped is-grouped-right">
              <p className="control">
                <button type="button" className="button is-link btn-standard" onClick={() => { navigate(-1) }}>Back</button>
              </p>
              {id === '0' && <p className="control">
                <button type="button" className="button is-primary btn-standard" onClick={() => { upsert() }}>
                  {id !== '0' ? 'Update' : 'Create'}
                </button>
              </p>}
            </div>
          </div>
        </div>

        {showConfirmDialog && <ConfirmDialog {...{ ...confDialogRecipe }}
          hide={() => setshowConfirmDialog(false)}
        // executeCallback={() => }
        />}
      </div>
    </>
  )
}

export default CreateTargets;
