import { useState } from "react";
import * as service from "../services";
import { startLoading, stopLoading, isEmpty } from '../components/Util';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function ResetPassword(props) {
    const navigate = useNavigate();
    const location = useLocation();
    const [username, setUserName] = useState(location.state);
    const [password1, setPassword1] = useState('')
    const [password2, setPassword2] = useState('')
    const [errors, setError] = useState({})

    const validate = () => {
        let errors = {}
        if (!username || username.length <= 0) errors.username = 'Please enter Email Address.';
        setError(errors)
        return isEmpty(errors)
    }

    const validatePassword = () => {
        let errors = {}
        var errmsg;
        if (!password1 || password1.length <= 0) {
            errors.password1 = 'Please enter New Password.';
        } else {
            if (password1.length <= 7) {
                errmsg = "8 characters";
            }
            if (!password1.match(/[a-z]+/)) {
                errmsg ? errmsg = errmsg + ", 1 lowercase character" : errmsg = "1 lowercase character";
            }
            if (!password1.match(/[A-Z]+/)) {
                errmsg ? errmsg = errmsg + ", 1 uppercase character" : errmsg = "1 uppercase character";
            }
            if (!password1.match(/[0-9]+/)) {
                errmsg ? errmsg = errmsg + ", 1 number" : errmsg = "1 number";
            }
            if (errmsg) {
                errors.password1 = `Password should contain atleast ${errmsg}`;
            }
        }
        if (!password2 || password2.length <= 0) errors.password2 = 'Please enter Confirm New Password.';
        if (password1 && password2 && password1 !== password2) errors.password2 = 'Password mismatch.';
        setError(errors)
        return isEmpty(errors)
    }

    const handleResetSubmit = () => {
        if (validatePassword()) {
            startLoading();
            validate();
            service.reset_password({ username: username, password: password1 }, res => {
                stopLoading();
                navigate('/login')
            });
        }
    }

    return (
        <>
            <div className="login lims-eln-login">
                <div className="lm-login-inner">
                    <div className="form-signin">
                        <div className="logos lims-eln-logo"></div>
                        <form action="#" className="login-form">
                            <div className="field">
                                <label className="label">Email address</label> <div className="control has-icons-left">
                                    <input autoComplete="username" type="email" className="input" id="floatingInput" placeholder="name@example.com"
                                        value={username || ''} disabled={true} onChange={(e) => setUserName(e.target.value)}
                                        required
                                        autoFocus />
                                    <span className="icon is-small is-left">
                                        <i className="mdi mdi-account"></i>
                                    </span>
                                </div>
                            </div>

                            <div className="field">
                                <label className="label">Password</label>
                                <div className="control has-icons-left">
                                    <input autoComplete="new-password" type="password" className="input" id="floatingPassword" placeholder="Password"
                                        onKeyUp={() => { validatePassword() }} value={password1 || ''}
                                        onChange={(e) => setPassword1(e.target.value)}
                                        required
                                    />
                                    <span className="icon is-small is-left">
                                        <i className="mdi mdi-key"></i>
                                    </span>
                                </div>
                                {errors.password1 && <p className="help is-danger"><small>{errors.password1}</small></p>}
                            </div>
                            <div className="field">
                                <label className="label">Verify Password</label>
                                <div className="control has-icons-left">
                                    <input autoComplete="new-password" type="password" className="input" id="floatingPassword2" placeholder="Password"
                                        value={password2 || ''}
                                        onChange={(e) => setPassword2(e.target.value)}
                                        onKeyUp={() => { validatePassword() }}
                                        required
                                    />

                                </div>
                                {errors.password2 && <p className="help is-danger"><small>{errors.password2}</small></p>}
                            </div>
                            <div className="buttons is-right">
                                <button type="button" className="button is-info btn-standard" onClick={() => { handleResetSubmit() }}><small>Submit</small></button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ResetPassword;
